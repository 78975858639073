@import "../../theme/app-theme.scss";
.ModalBill {
  display: flex;
  justify-content: center;
  align-items: center;
  
  &--print {
    display: flex;
    flex-direction: column;
    padding: 2rem 0 0 2rem;
  }

  &--container {
    background-color: $white;
    width: auto;
    padding: 5px;
  }

  &--closeButton {
    align-items: center;
    color: $primary_red;
    background: $primary_red;
    font-size: $big_letter;
    border-bottom-left-radius: 1rem;
    cursor: pointer;
  }

  body {
    overflow-y: scroll;
    min-height: 50vh;
    max-height: 50vh;
  }

  &--button {
    padding: 1rem;
  }
  p {
    margin: 0px;
    padding: 0px;
  }
  head {
    display: flex;
    justify-content: space-between;
    padding-left: 1rem;
  }
}
