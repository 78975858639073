@import "../theme/app-theme.scss";

.template {
  display: flex;
  flex-direction: column;
  margin: 0;

  @include laptop {
    flex-direction: row;
  }
}
