//colors
$primary_green: #426b1f;
$secondary_green: #2e7d32;
$green_text: #426b1f;
$primary_red: #de2b2b;
$secondary_red: #b30505;
$black: #000000;
$white: #ffffff;
$disabled_letter_gray: #7f839b;
$disabled_input_gray: #d0cbcb;
$alert_info: #1aa7c1;
$alert_danger: #d23d3d;
$alert_warning: #ffcf4d;
$alert_succes: #25cf7e;
//margins
$margins_mobile: 0.5rem;
$margins_tablet: 1rem;
$margins_laptop: 2rem;
$margins_desktop: 2rem;
//font-size
$small_letter: 1rem;
$medium_letter: 1.5rem;
$big_letter: 2rem;
//neutral
$neutral-100: #fafbfc;
$neutral-200: #e2e7ed;
$neutral-700: #626277;
$neutral-900: #292730;

$ff-Cabin-Regular: "Cabin", Helvetica, Arial, sans-serif;
//responsive
@mixin mobile {
  @media screen and (min-width: 375px) {
    @content;
  }
}

@mixin tablet {
  @media screen and (min-width: 768px) {
    @content;
  }
}

@mixin laptop {
  @media screen and (min-width: 1100px) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: 1400px) {
    @content;
  }
}
