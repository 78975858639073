@import "../../theme/app-theme.scss";
.ModalBills {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &--container {
    background-color: $white;
    overflow-y: scroll;

    &--title {
      display: flex;
      justify-content: space-between;
    }

    h1 {
      color: $black;
      text-align: center;
      font-size: $medium_letter;
      margin: 2rem 2rem 0 2rem;

      @include laptop {
        text-align: start;
      }
    }

    &--icon-back {
      align-items: center;
      color: $primary_red;
      background: $primary_red;
      font-size: $big_letter;
      border-bottom-left-radius: 1rem;
      cursor: pointer;
    }

    &--content--info {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      max-width: 100%;
      padding: 1rem;

      @include laptop {
        padding: 2rem;
      }
    }

    &--content--table {
      padding: 1rem 2rem;
    }
  }

  hr {
    border-bottom: 1px solid $disabled_letter_gray;
  }
}
