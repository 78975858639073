@import "../../theme/app-theme.scss";

.welcome {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: $margins_mobile;

  @include tablet {
    margin: $margins_tablet;
  }

  @include laptop {
    width: 100%;
    margin: 0 $margins_laptop 0 0;
  }

  @include desktop {
    margin: 0 $margins_desktop 0 0;
  }

  &--figure {
    margin: 0;
  }

  &--h1 {
    color: $green_text;
    text-align: center;
    font-size: $medium_letter;
    margin: 0;

    @include tablet {
      font-size: $big_letter;
    }

    @include laptop {
      display: none;
    }
  }

  &--h2 {
    color: $green_text;
    text-align: center;
    font-size: $small_letter;

    @include tablet {
      font-size: $medium_letter;
    }
  }

  &--img {
    max-width: 20rem;
    margin: 2rem;
  }
}
