@import "../../theme/app-theme.scss";

.bills {
  margin: $margins_mobile;

  @include tablet {
    margin: $margins_tablet;
  }

  @include laptop {
    width: 100%;
    margin: 0 $margins_laptop 0 0;
  }

  @include desktop {
    margin: 0 $margins_desktop 0 0;
  }

  &--h1 {
    color: $green_text;
    text-align: center;
    font-size: $medium_letter;
    margin: 0 0 0.5rem 0;

    @include laptop {
      text-align: start;
    }
  }

  &--content--info {
    max-width: 100%;
    padding: 1rem;
    border-radius: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.15);
  }

  &--info {
    display: flex;
    flex-direction: column;

    @include laptop {
      flex-direction: row;
      gap: 0.5rem;
    }

    &.textarea{
        display: flex;
        flex-direction: column;
        gap: 0;
    }
  }

  &--content--button {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: flex-end;
    margin: 1rem; 
  }
}
