@import "./theme/app-theme.scss";

* {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.amplify-button {
  border: 0.1rem solid $black;
  font-size: $small_letter;
}

.amplify-button[data-variation="primary"] {
  background: $primary_green;
  border-radius: 1rem;
  border: 1px solid $secondary_green;
}

.amplify-button:hover[data-variation="primary"] {
  background: $secondary_green;
  border: 1px solid $secondary_green;
}

.amplify-button--link {
  color: $primary_green;
}

.amplify-input {
  border-radius: 1rem;
  border: 0.1rem solid $black;
  font-size: $small_letter;
}

.amplify-image{
  width: 50%;
}

[data-amplify-authenticator][data-variation=modal]{
  background-color: $white;
}
