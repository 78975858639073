@import "../../theme/app-theme.scss";

.panel {
  display: flex;
  flex-direction: column;
  margin: $margins_mobile;
  gap: 1rem;
  cursor: pointer;

  @include tablet {
    margin: $margins_tablet;
  }

  @include laptop {
    margin: $margins_laptop;
    max-width: 20%;
  }

  @include desktop {
    margin: $margins_desktop;
  }

  &--button {
    @include laptop {
      display: none !important;
    }
  }

  &--nav {
    opacity: 0;
    height: 0vh;
    pointer-events: none;
    transition: all 0.5s ease;

    &.isActive {
      opacity: 1;
      min-height: 60vh;
      pointer-events: auto;
    }

    @include laptop {
      min-height: 60vh;
      opacity: 1;
      pointer-events: auto;
    }
  }

  &--ul {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    gap: 1rem;

    @include laptop {
      gap: 2rem;
    }
  }

  &--li {
    list-style: none;
  }
}
