@import "../../theme/app-theme.scss";

.sales {
  margin: $margins_mobile;

  @include tablet {
    margin: $margins_tablet;
  }

  @include laptop {
    width: 100%;
    margin: 0 $margins_laptop 0 0;
  }

  @include desktop {
    margin: 0 $margins_desktop 0 0;
  }

  &--h1 {
    color: $green_text;
    text-align: center;
    font-size: $medium_letter;
    margin: 0 0 0.5rem 0;

    @include laptop {
      text-align: start;
    }
  }

  &--content--info {
    max-width: 100%;
    padding: 1rem;
    border-radius: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.15);
  }

  &--info {
    display: flex;
    flex-direction: column;

    @include laptop {
      flex-direction: row;
      gap: 0.5rem;
    }
  }

  &--content--button {
    @include laptop {
      display: flex;
      flex-direction: row;
    }
  }

  &--content--button2 {
    display: flex;
    gap: 1rem;
    margin: 1rem 0;

    @include laptop {
      padding: 0 0.5rem;
    }
  }

  &--circularprogress {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2rem;
    justify-content: center;
  }
}

.valueToPayContainer {
  margin: 1rem;
}
.valueToPay {
  font-weight: 600 !important;
  font-size: $big_letter !important;
  color: $secondary_green !important;
  margin: 1rem;
}
