@import "../../theme/app-theme.scss";

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: $margins_mobile;
  gap: 1rem;

  @include tablet {
    margin: $margins_tablet;
  }

  @include laptop {
    margin: $margins_laptop;
  }

  @include desktop {
    margin: $margins_desktop;
  }

  &--figure {
    margin: 0;
  }

  &--img {
    max-width: 5rem;
    max-height: 5rem;
    cursor: pointer;

    @include laptop {
      display: none;
    }
  }

  &--h2 {
    display: none;
    margin: 0;
    color: $green_text;
    font-family: Newsreader;
    font-size: $big_letter;
    font-weight: 500;
    cursor: pointer;

    @include laptop {
      display: flex;
    }
  }

  &--label {
    font-size: $small_letter;
    font-weight: 600;

    @include tablet {
      font-size: $medium_letter;
    }
  }
}
