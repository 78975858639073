@import "../../theme/app-theme.scss";

.inventory {
  margin: $margins_mobile;

  @include tablet {
    margin: $margins_tablet;
  }

  @include laptop {
    width: 100%;
    margin: 0 $margins_laptop 0 0;
  }

  @include desktop {
    margin: 0 $margins_desktop 0 0;
  }

  &--h1 {
    color: $green_text;
    text-align: center;
    font-size: $medium_letter;
    margin: 0 0 0.5rem 0;

    @include laptop {
      text-align: start;
    }
  }

  &--content--info {
    max-width: 100%;
    padding: 1rem;
    border-radius: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.15);
  }

  &--info {
    display: flex;
    flex-direction: column;

    @include laptop {
      flex-direction: row;
      gap: 0.5rem;
    }
  }

  &--content--button {
    display: flex;
    flex-direction: row;
    margin: 1rem 0;
    gap: 1rem;
    align-items: flex-end;
  }
}

.modalDeleteProduct {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &--container {
    background-color: $white;

    &--title {
      display: flex;
      justify-content: space-between;
    }

    h1 {
      color: $black;
      text-align: center;
      font-size: $medium_letter;
      margin: 2rem 2rem 0 2rem;

      @include laptop {
        text-align: start;
      }
    }

    &--icon-back {
      align-items: center;
      color: $primary_red;
      background: $primary_red;
      font-size: $big_letter;
      border-bottom-left-radius: 1rem;
      cursor: pointer;
    }

    &--body {
      padding: 2rem;
    }
  }

  &--footer {
    display: flex;
    padding: 1rem;
    justify-content: space-between;
    .button {
      margin:10px;
    }
  }
}
